import React from 'react';
import styled from 'styled-components';
import Template from "../../../shapes";
import DomChoices from "./DomChoices";
import { useSelector } from "react-redux";
import { Store } from "../../../store/reducers/Store";
import { Status } from "../store/actions/types";
import { useChat } from "../store/useChat";
import {settings} from "../../../config";

export interface AnswerProps {
  onClick(id?: string, text?: string): void;
}

export interface AnswerProps {
  onClick(id?: string, text?: string): void;
}

const Container = styled.div`
  display: flex;
  position: absolute;
  width: 310px;
  height: 190px;
  bottom: ${settings.chat_distance_bottom - 40}px;
  right: 40px;
`;

const Answer = () => {
  const {onAnswer} = useChat();
  const show_answer = useSelector( (state: Store) =>
    state.chat.status === Status.AWAITING_ANSWER
  );

  if (!show_answer) return null;

  return (
    <Container>
      <Template type='chat_answer'>
        <DomChoices onClick={ onAnswer } />
      </Template>
    </Container>
  );
};

export default Answer
