import { useCallback, useEffect, useMemo } from "react";
import { useScene } from "../store";
import { RefProps } from "../Chat/types";
import { usePrevious } from "../../assets/usePrevious";
import { ReturnRefs } from "../../assets/useDomNodes";
import { useSelector } from "react-redux";
import { Store } from "../../store/reducers/Store";
import { SceneType } from "../types";

// Guess the RefProps are the Problem here.
// Todo fix the problem and get rid of animation: any
// type Animation = (props: RefProps, delay: number) => ({
//   in: (onComplete: () => void) => void;
//   out: (onComplete: () => void) => void;
// })

export const useVisibilityAnimation = (
  dom: ReturnRefs,
  animation: any,
  type: SceneType
) => {
  const show = useSelector( (state: Store) => state.sequence.show );
  const scene = useScene();
  const current = useSelector( (state: Store) => state.sequence.current );
  const prevShow = usePrevious( show );

  const isSameType = useMemo(() =>
    scene.getCurrentScene().type === type
  , [scene, type]);

  const shouldAnimateIn = useMemo(() =>
    show
    && !prevShow
    && current.length
    && isSameType
  , [current.length, isSameType, prevShow, show]);

  const shouldAnimateOut = useMemo(() =>
    !show
    && prevShow
    && isSameType
  , [isSameType, prevShow, show]);

  const delay = useMemo(() => {
    return scene.getCurrentScene().delay
  }, [scene]);

  const animate = useMemo(
    () => animation(
      dom.domNodes as unknown as RefProps,
      delay
    ),
    [animation, delay, dom.domNodes]
  );

  const animateIn = useCallback(() => {
    scene.setAnimatedIn( false );
    scene.setAnimatedOut( false );
    animate.in( () => scene.setAnimatedIn( true ) )
  }, [animate, scene]);

  const animateOut = useCallback(() => {
    scene.setAnimatedIn( false );
    scene.setAnimatedOut( false );
    animate.out( () => scene.setAnimatedOut( true ) )
  }, [animate, scene]);


  useEffect( () => {
    if ( dom.is_ready ) {
      shouldAnimateIn && animateIn();
      shouldAnimateOut && animateOut();
    }
  }, [animateIn, animateOut, dom.is_ready, shouldAnimateIn, shouldAnimateOut] );
};
