import React, { useEffect, useState } from 'react';
import DomMessage from "./Messages/DomMessage";
import { useScene } from "../index";
import { SetDomNodes } from "../../assets/useDomNodes";
import { useSelector } from "react-redux";
import { Store } from "../../store/reducers/Store";

interface Props {
  setRefs: SetDomNodes;
}

const DomAnswer: React.FC<Props> = (
  {
    setRefs,
  }
) => {
  const { getCurrentImage } = useScene();
  const { chat_face, chat_background } = getCurrentImage( 'user' );
  const answer = useSelector( (state: Store) => state.chat.answer );

  const [text, setText] = useState('');

  useEffect(() => {
    if (answer.length && !text.length) {
      setText(answer)
    }
  }, [answer, text]);

  return (
    <DomMessage
      is_answer
      chat_face={ chat_face }
      chat_background={ chat_background }
      text={ text}
      setRefs={ setRefs }
      show={!!text.length}
    />
  );
};

export default DomAnswer
