import { State, Status } from "./actions/types";


export type Action = {
  type: string;
  [index: string]: any;
}

export const initialState: State = {
  messages: [],
  status: Status.READY,
  answer: '',
  target: '',
};

export default function reducer(state: State = initialState, action: Action) {
  switch ( action.type ) {

    case 'ADD_MESSAGE':
      return {
        ...state,
        messages: state.messages.concat(action.message),
      };

    case 'AWAIT_ANSWER':
      return {
        ...state,
        status: Status.AWAITING_ANSWER,
        answer: initialState.answer
      };

    case 'PROVIDE_ANSWER':
      return {
        ...state,
        status: Status.ANSWER_GIVEN,
        answer: action.answer,
        target: action.target || '',
      };

    case 'ANSWER_GIVEN':
      return {
        ...state,
        status: Status.ANSWER_GIVEN,
      };

    case 'SET_READY':
      return {
        ...state,
        status: Status.READY,
        answer: initialState.answer,
        target: initialState.target,
      };

    case 'RESET_CHAT':
      return initialState;

    default: return state
  }
};
