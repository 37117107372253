import { useScene as useSceneHook } from "./useScene";
import { initialState } from "./reducers";
import { Actions } from "./actions/types";

const useScene: () => Actions = useSceneHook;

export {
  useScene,
  initialState
}
