import { useEffect, useState } from "react";
import { usePrevious } from "../assets/usePrevious";
import { Categories, useApi } from "../api/useApi";
import { AnimationReturn } from "../assets/useAnimation";

interface AppsReturn {
  open: number;
  setOpen(i: number): void;
  visibleImages: string[];
  categories: Categories;
}

export const useApps = (timeline: AnimationReturn): AppsReturn => {
  const [ open, setOpen ] = useState( -1 );
  const prevOpen = usePrevious( open );
  const api = useApi();
  const categories = api.categories();
  const [ initial, setInitial ] = useState( true );
  const [ visibleImages, setVisibleImages ] = useState<string[]>( [] );


  useEffect( () => {
    const change = open !== prevOpen;
    const idFromIndex = (): string => {
      const key = Object.values( categories )[open] || {};
      return key.id || '';
    };
    const id = idFromIndex();
    const images = id === '' ? [] : api.images( id );

    const animateIn = () => {
      if ( initial ) {
        setInitial( false );
      }
      if ( visibleImages !== images ) {
        setVisibleImages( images )
      }
      if ( prevOpen === -1 ) {
        timeline.in()
      }
    };

    const animateOut = () => {
      setOpen( -1 );
      timeline.out()
    };

    if ( change && open !== -1 ) {
      animateIn()
    }

    if ( change && open === -1 ) {
      animateOut()
    }
  }, [ api, categories, initial, open, prevOpen, timeline, visibleImages ] );

  return {
    visibleImages,
    open,
    categories,
    setOpen
  }

};