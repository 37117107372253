import { SceneList } from "../types";
import { globalScenes as globalSceneList } from "../stories/globalScenes";
import { addScene } from "../store/actions/methods/addScene";

const scenes = addScene(
  [],
  globalSceneList,
  'GLOBAL'
);

export const globalScenes = () => {
  return provideOrder( scenes );
};

const provideOrder = (scenes: SceneList = []): SceneList => {
  return scenes.map( (scene, i) => ({
    ...scene,
    order: scene.order || 100 + i
  }) )
};
