import React, { Fragment, useMemo } from 'react';
import { Message as MessageType } from "../store/actions/types";
import Message from "./Message";

interface Props {
  messages: MessageType[];
}

const MessageList: React.FC<Props> = ({ messages }) => {
  const messageMap = useMemo( () => {
    return messages.map( (message) => (
      <Message
        is_answer={ message.type === 'ANSWER' }
        key={ message.id }
        image={ message.image }
        text={ message.text }
      />
    ) )
  }, [ messages ] );

  return (
    <Fragment>
      { messageMap }
    </Fragment>
  );
};

export default MessageList
