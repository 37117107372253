import React, { useMemo, useState } from 'react';
import PortfolioDom from "./PortfolioDom";
import { animatePortfolioOpen } from "./animation/animateOpen";
import { useAnimation } from "../assets/useAnimation";
import Slider from "./slider/Slider";
import { useApps } from "./useApps";
import { useScene } from "../scene";
import { Pages } from "../constants/Pages";
import Loading, {useLoading} from "../components/Loading/Loading";
import AppButton from "./AppButton";
import Categories from "./Categories";
// This should be the style for desktop. Mobile should have own styles.
// Should be full screen without the frame. And a special Home Button


const tutorialHome = {
  delay: 3,
  frames: [ { text: 'Wenn du zurück willst klick einfach auf den Home Button des iPads' } ]
};


const Portfolio = () => {
  const { addScene } = useScene();
  const [ showBackTutorial, setShowBackTutorial ] = useState( true );
  const [ currentPreview, setCurrentPreview ] = useState( -1 );
  const [ openSlider, setOpenSlider ] = useState( false );
  const { setDomNodes, timeline } = useAnimation( animatePortfolioOpen );
  const [ loading, onLoad ] = useLoading();
  const [showCategory, setShowCategory] = useState('');
  const {
    open,
    setOpen,
    categories,
    visibleImages,
  } = useApps( timeline );


  const onClickHome = () => {
    setCurrentPreview( -1 );
    setShowCategory('')
  };

  const onClickApp = (name: string) => {
    setShowCategory(name)
    if ( showBackTutorial ) {
      addScene( tutorialHome, Pages.PORTFOLIO );
      setShowBackTutorial( false );
    }
  };

  const setPreview = (index: number) => {
    if ( index !== currentPreview ) {
      setCurrentPreview( index );
      onOpenSlider();
    }
  };

  const onOpenSlider = () => {
    setOpenSlider( true )
  };

  const onCloseSlider = () => {
    setOpenSlider( false )
  };

  return (
    <div>
      <Loading show={ loading }>
        <PortfolioDom
          onClickHome={ onClickHome }
          category={ showCategory }
          visibleImages={ visibleImages }
          setDomNodes={ setDomNodes }
          onClickImage={ setPreview }
          onLoad={ onLoad }
        >
          <Categories
            // categories={categories}
            onClickApp={onClickApp}
          />
        </PortfolioDom>
        <Slider
          images={ visibleImages }
          initialIndex={ currentPreview }
          show={ openSlider }
          onClose={ onCloseSlider }
          fullScreen
        />
      </Loading>
    </div>
  );
};

export default Portfolio
