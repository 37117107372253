import React from "react";
import {
  AnswerFlash,
  AnswerFlashBackground,
  AnswerUser,
  AnswerUserImage
} from "../Styles";
import defaultImage from "../../../assets/img/dialog/user.png";

interface Props {
  flashRef: any;
  userRef: any;
  backgroundRef: any;
}

export const AnswerScreen: React.FC<Props> = (
  {
    flashRef,
    userRef,
    backgroundRef
  }
) => (
  <AnswerUser id="Answer">
    <AnswerFlashBackground ref={backgroundRef} id="Flash Background">
      <svg
        viewBox="0 0 1310 1080"
        width="700"
        height="700"
        preserveAspectRatio="none"
      >
        <polygon
          fill="#484c51"
          points="2500,0 2500,1080 20,1080 402,0 "
        />
      </svg>
    </AnswerFlashBackground>
    <AnswerUserImage
      ref={userRef}
      src={ defaultImage }
      alt="Tüten Guy"
    />
    <AnswerFlash ref={flashRef} id="Answer Flash">
      <svg
        id="flash"
        viewBox="0 0 1310 1080"
        width="700"
        height="700"
        preserveAspectRatio="none"
      >
        <polygon
          fill="black"
          points="416,0 396,0 300,0 280,0 207,241 263.8,204.7 90,780 112.5,775.1 0,1080 20,1080 68,1080 88,1080 254,573 228,540"
        />
        <polygon
          fill="white"
          points="286,0 217,226 274,189 97.7,775.3 120,771 6,1080 74,1080 240,573 214,540 402,0"
        />
      </svg>
    </AnswerFlash>
  </AnswerUser>

);
