import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Apostrophe from "./Apostrophe";
import { apostropheAnimation } from "./animation/apostropheAnimation";
import {useScene} from "../../scene/store";
import { useSelector } from "react-redux";
import { Store } from "../../store/reducers/Store";

const Container = styled.div`
  position: absolute;
  right: -80px;
  top: 30px;
`;

const AnimatedApostrophe = () => {
  const scene = useScene();
  const is_animated_in = useSelector( (state: Store) => state.sequence.is_animated_in );
  const ref = useRef( null );

  useEffect( () => {
    if ( ref.current ) {
      //@ts-ignore
      apostropheAnimation( ref.current )
        .play()
    }
  } );

  if (scene.showAnswer() || !is_animated_in) {
    return null
  }

  return (
    <Container ref={ ref }>
      <Apostrophe />
    </Container>
  );
};

export default AnimatedApostrophe
