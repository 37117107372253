import { ChatImage, chatImages, ChatImages } from "../../../../../constants/chatImages";
import { DialogImage, dialogImages, DialogImages } from "../../../../../constants/dialogImages";

const default_image = 'bente';

export const getImage = (image: ChatImage | DialogImage) => {
  const chatKey = getChatKey( image );
  const dialogKey = getDialogKey( image );

  return {
    chat_face: chatImages[chatKey].face,
    chat_background: chatImages[chatKey].background,
    dialog: dialogImages[dialogKey]
  }
};

const available_for_chat = Object.keys( chatImages );

const getChatKey = (image: ChatImage | DialogImage): keyof ChatImages =>
  new Set( available_for_chat ).has( image )
    ? image as keyof ChatImages
    : default_image;

const available_for_dialog = Object.keys( dialogImages );

const getDialogKey = (image: ChatImage | DialogImage): keyof DialogImages =>
  new Set( available_for_dialog ).has( image )
    ? image as keyof DialogImages
    : default_image;
