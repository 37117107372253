import { getFrameIndex } from "../store/actions/methods/helper/getFrameIndex";
import { useSceneGetter } from "./useSceneGetter";
import { actions as getActions } from "../store/actions/methods/actions";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { useTarget } from "./useTarget";
import { useNavigation } from "../../assets/useNavigation";
import { useLocation } from "react-router";
import { Store } from "../../store/reducers/Store";
import { SequenceState } from "../store/actions/types";

export const useOnProgress = (): () => void => {
  const dispatch = useDispatch();
  const { currentScene } = useSceneGetter();
  const actions = getActions( dispatch );
  const getTarget = useTarget();
  const navigate = useNavigation();
  const location = useLocation();
  const is_ready = useSelector( (state: Store) => state.sequence.status === SequenceState.AWAIT_START );

  const incrementToTarget = useCallback( (target: string) => {
    const index = getFrameIndex( currentScene, target );
    if ( index !== -1 ) {
      actions.SET_FRAME_INDEX( index );
    } else {
      console.error( 'This id does not exist ', target );
      actions.INCREMENT_FRAME();
    }
  }, [ actions, currentScene ] );

  const finish_after_navigation = useCallback((target: string) => {
    const is_current_page = target === location.pathname;

    return is_ready && is_current_page
  }, [is_ready, location.pathname]);

  const navigateToPage = useCallback((target: string) =>
    finish_after_navigation(target)
      ? actions.FINISH_SCENE()
      : navigate(target)
  , [actions, finish_after_navigation, navigate]);

  const handleTarget = useCallback((target: string) => {
    const is_page_navigation = target.startsWith('/');

    is_page_navigation
      ? navigateToPage(target)
      : incrementToTarget(target)

  }, [incrementToTarget, navigateToPage]);

  return useCallback( (target?: string) => {
      const newTarget = getTarget(target);

      if ( newTarget.break ) {
        actions.FINISH_SCENE()
      } else {
        if ( newTarget.isValid ) {
          handleTarget( newTarget.id );
        } else {
          actions.INCREMENT_FRAME();
        }
      }
    }
    , [actions, getTarget, handleTarget] );

};

