import { Pages } from "../constants/Pages";
import { ChatImage } from "../constants/chatImages";
import { DialogImage } from "../constants/dialogImages";

export type DialogType = 'DIALOG' | 'CHAT';

export enum SceneType {
  DIALOG = 'DIALOG',
  CHAT = 'CHAT'
}

export enum SceneEvent {
  EXPLOSION = 'EXPLOSION',
  RATING = 'RATING',
  FLASH = 'FLASH',
  LIGHTNING = 'LIGHTNING',
  COOKIE_CONSENT = 'COOKIE_CONSENT'
}


export type PartialFrame = Partial<Frame>;
export type PartialFrameProps = Omit<PartialFrame, 'choices'>
export interface FramePropsWithChoices extends PartialFrameProps {
  choices: ChoiceProps[];
}

export interface FrameProps extends PartialFrameProps {
  choices?: (ChoiceProps | string)[];
}

export interface ChoiceProps {
  target?: string;
  text: string;
}

export interface Frame {
  id: string;
  type: 'TEXT' | 'ANSWER' | 'CHOICE';
  target?: string;
  text?: string;
  image: ChatImage | DialogImage;
  choices?: ChoiceProps[];
  event?: SceneEvent,
  name: string
}

type PartialScene = Omit<Partial<SceneInterface>, 'frames'>;

export interface SceneProps extends PartialScene {
  frames: FrameProps[];
}

export interface SceneInterface {
  id: string;
  delay: number;
  type: DialogType;
  image: ChatImage | DialogImage;
  frames: Frame[];
  order?: number;
  origin: string;
  repeat: boolean;
  endless: boolean;
  name: string;
}


export interface StartSceneConfig {
  localScenes: CreateScene | CreateScene[];
  disableGlobalScenes: boolean;
  origin: Pages;
}

export interface StartSceneProps {
  localScenes?: CreateScene | CreateScene[];
  disableGlobalScenes?: boolean;
  origin: Pages;
}

export type CreateScene = SceneProps | string;

export type SceneList = SceneInterface[];

export type OnChangeAction = (current: string) => void;
export type OnEmptyChangeAction = () => void;
