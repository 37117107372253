import { useHistory, useLocation } from "react-router";
import { usePrevious } from "./usePrevious";
import { useCallback, useEffect } from "react";
import { useMenu } from "../menu/store";
import { useDispatch } from "react-redux";

export const useBrowserNavigationHandler = () => {
  const location = useLocation().pathname;
  const history = useHistory();
  const prevLocation = usePrevious(location);
  const menu = useMenu()[1];
  const dispatch = useDispatch();

  const handleNavigation = useCallback((to: string) => {
    menu.close();
    dispatch( { type: 'SET_PAGE', page: to } );
    dispatch({type: 'RESET_CHAT'})
  }, [dispatch, menu]);

  useEffect(() => {
    if (location !== prevLocation && history.action === 'POP') {
      handleNavigation(location);
    }
  })
};
