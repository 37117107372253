import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { StartSceneProps } from "../types";
import { start as createStart } from "../store/actions/methods/start";
import { Store } from "../../store/reducers/Store";
import { finish as finishScene } from "../store/actions/methods/finish";
import { useSceneGetter } from "./useSceneGetter";

interface Return {
  initializePage(): void;
  setupSequence(config: StartSceneProps): void;
  updateCurrent(): void;
  show(): void;
  hide(): void;
  nextScene(): void;
  setToReady(): void;
  setToStart(): void;
}

export const useSceneEventsActions = (): Return => {
  const dispatch = useDispatch();
  const location = useLocation();
  const session = useSelector( (state: Store) => state.session );
  const sequence = useSelector( (state: Store) => state.sequence );
  const scenes = useSelector( (state: Store) => state.sequence.scenes );
  const { next } = useSceneGetter();

  const initializePage = useCallback( () => {
    dispatch( {
      type: 'SET_PAGE',
      page: location.pathname
    } )
  }, [ dispatch, location.pathname ] );

  const setupSequence = useCallback( (config: StartSceneProps) => {
      const update = createStart( scenes, config );
      dispatch( {
        type: 'SET_UP_SEQUENCE',
        scenes: update.scenes,
        disableGlobal: update.disableGlobal,
      } )
    }, [ dispatch, scenes ]
  );

  const updateCurrent = useCallback( () => {
    dispatch( {
      type: 'SET_CURRENT',
      current: next
    } )
  }, [ dispatch, next ] );

  const show = useCallback( () => {
    dispatch( { type: 'SHOW' } )
  }, [ dispatch ] );

  const hide = useCallback( () => {
    dispatch( { type: 'HIDE' } )
  }, [ dispatch ] );

  const setToReady = useCallback( () => {
    dispatch( { type: 'AWAIT_READY' } )
  }, [ dispatch ] );

  const setToStart = useCallback( () => {
    dispatch( { type: 'START' } )
  }, [ dispatch ] );

  const nextScene = useCallback( () => {
    const next = finishScene( sequence, session );
    dispatch( {
      type: 'NEXT_SCENE',
      current: next.current,
      delay: next.delay,
      played: next.played,
    } )
  }, [dispatch, sequence, session] );

  return {
    initializePage,
    setupSequence,
    show,
    hide,
    updateCurrent,
    nextScene,
    setToReady,
    setToStart,
  }
};
