// import axios from 'axios';
// import url from '../constants/url';
import {getImages} from "./getImages";
import {getCategories} from "./getCategories";
import {categories} from "./categories";

export type Categories = CategoryType[];

export interface CategoryType {
  name: string;
  img: string;
  img_animated: string;
  id?: string;
}


interface Return {
  categories(): Categories;
  images(category: string): any;
}

const createMockCategories = (): Categories => {
  const c = getCategories();
  const list = Object.keys(c);
  // @ts-ignore
  return list.map(category => ({...categories[category], id: category}));
};

export const useApi = (): Return => {

  // const images = useMemo(() => getImages('enter category here'), []);

  const getCategories = () => {
    // axios.get(url.base)
    return createMockCategories()
  };

  return {
    categories: getCategories,
    images: (category: string) => getImages(category)
  }
};
