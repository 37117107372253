import { State } from "./types";

export const initialState: State = {
  isLoading: false,
  categories: []
};

type Action = {
  type: string;
  [index: string]: any;
}

export default function reducer(state: State = initialState, action: Action) {
  switch ( action.type ) {

    case 'FETCH_IMAGES':
      return {
        ...state,
        isLoading: true,
      }

    case 'STORE_IMAGES':
      return {
        isLoading: false,
        categories: action.images
      }

    default: return state
  }
};
