import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { Message, Status } from './actions/types';
import { useScene } from "../../index";
import { Store } from "../../../store/reducers/Store";
import { SceneType } from "../../types";
import { useTarget } from "../../hooks/useTarget";
import { id } from "../../../utilz/id";

export const useChat = () => {
  const dispatch = useDispatch();
  const { getNextFrame, onProgress, getCurrentScene } = useScene();
  const messages = useSelector( (state: Store) => state.chat.messages );
  const status = useSelector( (state: Store) => state.chat.status );
  const target = useSelector( (state: Store) => state.chat.target );
  const getTarget = useTarget();

  const next_frame = useMemo(() => {
    const {id} = getTarget(target);
    const next_already_exists = messages.filter(el => el.id === id)[0];
    const next = getNextFrame( id );
    // console.log(next_already_exists || next)

    return next_already_exists || next
  }, [getNextFrame, getTarget, messages, target]);

  const is_chat = useMemo(() =>
    getCurrentScene().type === SceneType.CHAT
  , [getCurrentScene]);

  const is_end = useMemo(() =>
    getTarget().break
  , [getTarget]);

  const add_message = useMemo( () =>
    is_chat && !is_end
  , [is_chat, is_end] );

  const onAnswer = (target: string, answer: string) => {
    dispatch( {
      type: 'PROVIDE_ANSWER',
      answer,
      target,
    } );
  };

  const addMessage = useCallback( (message: Message) => {
    const messageSet = new Set(messages);

    const newMessage = !messageSet.has(message)
      ? message
      : {
        ...message,
        id: message.id + id()
      };

    if ( add_message ) {
      dispatch( {
        type: 'ADD_MESSAGE',
        message: newMessage
      } )
    }
  }, [add_message, dispatch, messages] );

  const setReady = useCallback( () => {
    dispatch( {
      type: 'SET_READY',
    } )
  }, [ dispatch ] );

  const onProgressChat = () => {
    if ( status !== Status.AWAITING_ANSWER ) {
      addMessage( next_frame as Message );
      onProgress( target );
      setReady();
    }
  };

  return {
    onProgress: onProgressChat,
    onAnswer,
    messages,
    addMessage,
  }
};
