import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../store/reducers/Store";
import { useMemo, useState } from "react";
import { Categories } from "./store/types";

export const base = process.env.REACT_APP_BASE_URL;
export const categoriesUrl = base + 'categories/';
export const imagesUrl = base + 'images/';
const wakeUpCall = base + 'warmup/';

type Category = {
  icon?: string;
  iconOmo?: string;
  images: Image[];
  name: string;
}

type Image = {
  filename: string;
  title: string;
  categories?: string[];
  targetSize?: number;
}

type Return = {
  warmup(): void;
  isLoading: boolean;
  categories: Categories;
  getCategories(): void;
  getImages(category: string): Image[];
}

export const useApi = (): Return => {
  const categories = useSelector( (state: Store) => state.images.categories );
  const isLoading = useSelector( (state: Store) => state.images.isLoading );
  const dispatch = useDispatch();

  // Ich schätze weil ich hier den string erst zusammen setze wird er nicht
  // richtig gecached.

  const mapImages = (images: Image[]) =>
    images.map(image => ({
      ...image,
      filename: imagesUrl + image.filename
    }))

  const mapCategories = (categories: Categories) =>
      categories.map(category => ({
        ...category,
        icon: imagesUrl + category.icon,
        iconOmo: imagesUrl + category.iconOmo,
        images: mapImages(category.images)
      }))

  const mappedCategories: Categories = useMemo(() =>
    mapCategories(categories)
  , [categories, mapCategories])

  const getCategories = () => {
    if (!categories.length && !isLoading) {
      dispatch({
        type: 'FETCH_IMAGES'
      })
      fetchCategories();
    }
  }

  const fetchCategories = () => {
    axios.get( categoriesUrl )
      .then(resp => {
        storeImages(resp.data)
      })
      .catch(error => console.log('Nope'))
  }

  const storeImages = (categories: Categories) => {
    dispatch({
      type: 'STORE_IMAGES',
      images: mapCategories(categories)
    })
  }

  const warmup = () => {
    axios.get(wakeUpCall)
  }

  const getImages = (category: string) => {
    if (!category.length || !categories.length) {
      return []
    }
    const x = categories.filter(self => self.name === category)[0];
    return x.images
    // return categories[category]
  }

  return {
    getImages,
    warmup,
    isLoading,
    categories,
    getCategories //: fetchCategories
  }
}