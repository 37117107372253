import React, { Ref, useMemo, useState, Fragment } from 'react';
import {
  Container,
  Image as StyledImage,
  ImageBackground,
  ImageContainer,
  LoadingWrapper
} from "./ImagePreviewStyles";
import Dots from "../components/Dots/Dots";
import { useApi } from "./useApi";

interface Props {
  images: string[];
  visible: boolean;
  forwardRef: Ref<HTMLDivElement>;
  onClick?(index: number): void;
  category: string;
}

const Image = ({ src }: { src: string }) => {
  const [ loading, setLoading ] = useState( true );

  const setReady = () => setLoading( false );
  const hide_on_ready = loading ? { display: 'none' } : {};

  return (
    <Fragment>
      <LoadingWrapper>
        <Dots show={ loading } />
      </LoadingWrapper>
      <StyledImage src={ src } onLoad={ setReady } style={ hide_on_ready } />
    </Fragment>
  );
};

const ImagePreview: React.FC<Props> = (
  {
    category,
    // images,
    visible,
    forwardRef,
    onClick = () => {
    }
  }
) => {
  const {getImages} = useApi();

  const images = useMemo(() =>
    getImages(category)
  , [category, getImages]);

  // console.log(img)


  const Images = useMemo( () => (
    images.map( (image, i) => (
      <ImageContainer key={ i } onClick={ () => onClick( i ) }>
        <ImageBackground />
        <Image src={ image.filename } />
      </ImageContainer>
    ) )
  ), [ images, onClick ] );

  return (
    <Container
      visible={ visible }
      ref={ forwardRef }
    >
      { Images }
    </Container>
  );
};

export default ImagePreview
