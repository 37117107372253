import { useMemo } from "react";
import { SequenceState } from "../store/actions/types";
import { useSelector } from "react-redux";
import { Store } from "../../store/reducers/Store";
import { usePrevious } from "../../assets/usePrevious";
import { getNextScene } from "../store/actions/methods/helper/getNextScene";

type Return = {
  update_current_scene: boolean;
  hide_current_scene: boolean;
  next_scene: boolean;
  start_next: boolean;
  set_to_ready: boolean;
  set_to_start: boolean;
  ended_final_scene: boolean;
}

export const useSceneEventsValidation = (): Return => {
  const session = useSelector( (state: Store) => state.session );
  const status = useSelector( (state: Store) => state.sequence.status );
  const current = useSelector( (state: Store) => state.sequence.current );
  const sequence = useSelector( (state: Store) => state.sequence );
  const has_event = useSelector( (state: Store) => state.sequence.event.length );
  const is_animated_out = useSelector( (state: Store) => state.sequence.is_animated_out );
  const is_animated_in = useSelector( (state: Store) => state.sequence.is_animated_in );
  const show = useSelector( (state: Store) => state.sequence.show );
  const finish = useSelector( (state: Store) => state.sequence.finish );
  const was_finished = usePrevious( finish ) || finish;

  const next = useMemo(
    () => getNextScene( sequence, session ),
    [ sequence, session ]
  );

  const ready = useMemo( () =>
    status === SequenceState.AWAIT_START || status === SequenceState.AWAIT_READY
    , [ status ] );

  const needs_update = useMemo( () =>
    ready && next !== current
    , [ ready, next, current ] );

  const update_current_scene = useMemo( () => {
    const hide =
      is_animated_out
      && !next.length
      && current.length;
    const newCurrent =
      next.length
      && is_animated_out
    && !has_event;

    return Boolean( needs_update && (hide || newCurrent) )
  }, [current.length, has_event, is_animated_out, needs_update, next.length] );

  const hide_current_scene = useMemo( () =>
    needs_update && !!current.length && show
    , [ current.length, needs_update, show ] );

  const next_scene = useMemo( () =>
      Boolean( finish && is_animated_out )
    , [ finish, is_animated_out ] );

  const start_next = useMemo( () =>
    finish !== was_finished && !!current.length && current !== 'end'
    , [ current, finish, was_finished ] );

  const ended_final_scene = useMemo( () =>
    current === 'end' && status !== SequenceState.AWAIT_READY
    , [ current, status ] );

  const set_to_ready = useMemo( () =>
    is_animated_out && status === SequenceState.AWAIT_START
    , [ is_animated_out, status ] );

  const set_to_start = useMemo( () =>
    is_animated_in && show && status === SequenceState.AWAIT_READY
    , [ is_animated_in, show, status ] );

  return {
    update_current_scene,
    hide_current_scene,
    next_scene,
    start_next,
    set_to_ready,
    set_to_start,
    ended_final_scene,
  }
};
