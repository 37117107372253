import React from 'react';
import store from './store/store';
import { Provider } from 'react-redux';
import styled from "styled-components";
import AppLoader from "./AppLoader";
import AppBackground from "./AppBackground";
import 'setupGsap';

const Body = styled.div`
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  * {
    box-sizing: border-box;
  }
`;

function App() {

  return (
    <Provider store={ store }>
      <Body>
        <AppLoader />
        <AppBackground />
      </Body>
    </Provider>

  );
}

export default App;
