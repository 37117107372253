import { Store } from "use-global-hook";
import { Actions, State } from "./types";

export const open = (
  store: Store<State, Actions>,
) => {
  store.setState( {
    ...store.state,
    isOpen: true
  } );
};

export const close = (
  store: Store<State, Actions>,
) => {
  store.setState( {
    ...store.state,
    isOpen: false
  } );
};

export const toggle = (
  store: Store<State, Actions>,
) => {
  store.setState( {
    ...store.state,
    isOpen: !store.state.isOpen
  } );
};

export const setReady = (
  store: Store<State, Actions>,
) => {
  store.setState( {
    ...store.state,
    ready: true
  } );
};

export const disable = (
  store: Store<State, Actions>,
) => {
  store.setState( {
    ...store.state,
    isDisabled: true
  } );
};

export const enable = (
  store: Store<State, Actions>,
) => {
  store.setState( {
    ...store.state,
    isDisabled: false
  } );
};
