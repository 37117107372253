import { useCallback, useEffect, useMemo } from "react";
import { useSceneGetter } from "./useSceneGetter";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store/reducers/Store";
import { usePrevious } from "../../assets/usePrevious";
import { Frame, SceneEvent } from "../types";

export const useFrameEvents = () => {
  const dispatch = useDispatch();
  const { currentFrame } = useSceneGetter();
  const index = useSelector( (state: Store) => state.sequence.frameIndex );
  const prevIndex = usePrevious( index );
  const prevFrame = usePrevious( currentFrame );

  const needs_update = useMemo( () =>
    prevIndex !== undefined
    && index !== prevIndex
  , [ index, prevIndex ] );

  const trigger_event = useMemo( () => {
      return Boolean( prevFrame && needs_update && prevFrame.event )
    }
  , [needs_update, prevFrame] );


  const triggerEvent = useCallback( () => {
    const event = hasEvent(prevFrame) && prevFrame.event;
    dispatch({
      type: 'SET_EVENT',
      event
    })
  }, [dispatch, prevFrame] );

  useEffect( () => {
    trigger_event && triggerEvent()
  } )
};

interface FrameWithEvent extends Omit<Frame, 'event'> {
  event: SceneEvent
}

const hasEvent = (frame: Frame | undefined): frame is FrameWithEvent =>
  Boolean(frame && frame.event);
