import { Actions, State } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { Pages } from "../../constants/Pages";
import { Store } from "../../store/reducers/Store";
import { useCallback } from "react";
import { session } from "./actions/methods/session";

export const useSession = (): Actions => {
  const dispatch = useDispatch();
  const sessionState = useSelector( (state: Store) => state.session );
  const is_read = useSelector( (state: Store) => state.session.is_read );
  const times_visited = useSelector( (state: Store) => state.session.times_visited );
  const dsgvo_agreed = useSelector( (state: Store) => state.session.filtered_scenes.indexOf('dsgvo') !== -1 );
  const is_portrait = useSelector( (state: Store) =>
    state.session.screen_height > state.session.screen_width
  );

  const update = useCallback( (state: State, message?: string) => {
    dispatch( { type: 'UPDATE_SESSION', state } );
    session.writeState( state );
    if ( message ) {
      dispatch( { type: `__SESSION_${ message }__` } )
    }
  }, [ dispatch ] );

  const isReady = () => is_read;

  const agreeToDsgvo = () => {
    const state = agreeToDsgvoState( sessionState );
    update( state, 'AGREE_TO_DSGVO' )
  };

  const clearSession = () => {
    dispatch( { type: 'CLEAR_SESSION' } );
    session.clear()
  };

  const addVisitedPage = (page: Pages) => {
    // Initial loading page is missing here.
    // But sucks to add since this is loaded before React-Router
    const state = addPageToState( sessionState, page );
    update( state )
  };

  const addFinishedSceneToFilters = (id: string) => {
    const state = addFinishedSceneToFilterState(sessionState, id);
    update( state )
  };

  const isPortrait = () => is_portrait;

  const timesVisited = () => times_visited;
  const dsgvo = () => dsgvo_agreed;

  return {
    isReady,
    agreeToDsgvo,
    clearSession,
    addVisitedPage,
    timesVisited,
    dsgvo,
    addFinishedSceneToFilters,
    update,
    isPortrait
  }
};

const agreeToDsgvoState = (state: State) => ({
  ...state,
  dsgvo_agreed: true
});

const addPageToState = (state: State, page: string) => {
  const current = new Set( state.visited_pages );
  const visited_pages = current.has( page )
    ? state.visited_pages
    : state.visited_pages.concat( page );

  return {
    ...state,
    visited_pages
  }
};

const addFinishedSceneToFilterState = (state: State, id: string) => {
  const current_filter = state.filtered_scenes;
  const set = new Set(current_filter);
  // random ids start with _ and should not be added to filters
  const noChange = !id || set.has(id) || id.startsWith('_');
  if (noChange) return state;

  return {
    ...state,
    filtered_scenes: current_filter.concat(id)
  }
};