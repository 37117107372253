import React from 'react';
import styled from 'styled-components';
import { useSession } from "../../../session/store";

const Container = styled.div`
  display: flex;
`;

const TestUi = () => {
  const action = useSession();
  return (
    <Container>
      <button onClick={action.agreeToDsgvo}>agree to dsgvo</button>
      <button onClick={action.clearSession}>reset</button>
    </Container>
  );
};

export default TestUi
