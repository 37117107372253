import { config } from "../../config";

export type State = {
  show: boolean;
}

export type Action = {
  type: string;
  [index: string]: any;
}

export const initialState: State = {
  show: config.default_dev_mode,
};

export default function reducer(state: State = initialState, action: Action) {
  switch ( action.type ) {

    case 'ENABLE_DEV_MODE':
      return {
        ...state,
        show: true,
      };

    case 'DISABLE_DEV_MODE':
      return {
        ...state,
        show: false
      };

    default: return state
  }
};
