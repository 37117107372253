import {State} from "./types";

export type Action = {
  type: string;
  [index: string]: any;
}

export const initialState: State = {
  version: 1,
  dsgvo_agreed: false,
  times_visited: -1,
  visited_pages: [],
  filtered_scenes: [],
  is_read: false,
  screen_width: -1,
  screen_height: -1,
};

export default function reducer(state: State = initialState, action: Action) {
  switch ( action.type ) {

    case 'UPDATE_SESSION':
      return {
        ...action.state,
      };

    //  Not stored in localStorage, of course
    case 'SET_SCREEN_SIZE':
      return {
        ...state,
        screen_width: action.width,
        screen_height: action.height
      };

    case 'CLEAR_SESSION':
      return initialState;

    default: return state
  }
};
