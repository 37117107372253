import React, { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import { Shape, ShapeConstructor } from "../../shapes";
import Dots from "../../components/Dots";
import { useSelector } from "react-redux";
import { Store } from "../../store/reducers/Store";
import { Status } from "./store/actions/types";
import { useSceneGetter } from "../hooks/useSceneGetter";

const fadeIn = keyframes`
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Background = styled.div`
  position: relative;
  left: 50px;
  display: flex;
  width: 60px;
  height: 40px;
  animation: .7s ${ fadeIn } linear;
`;

const customShape = () => {
  return new ShapeConstructor()
    .rectangle( { fill: 'black', fillOpacity: .5 } )
    .randomize( { x: 50, y: 2, extend: true } )
};

const form = customShape();

const ChatDots = () => {
  const { nextFrame } = useSceneGetter();
  const awaiting_answer = useSelector( (state: Store) =>
    state.chat.status === Status.AWAITING_ANSWER
  );
  const next_is_answer = useMemo( () =>
    nextFrame.type === 'ANSWER'
    , [ nextFrame ] );

  // todo if target=break should also return null

  if ( next_is_answer || awaiting_answer ) return null;

  return (
    <Background>
      <Shape
        form={ form }
        textColor='black'
        wiggleOptions={ { x: 40, y: 10 } }
      >
        <Dots />
      </Shape>
    </Background>
  );
};

export default ChatDots
