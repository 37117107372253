import fl from '../assets/img/portfolio/apps/app-fledermaeuse.png';
import fl_animated from '../assets/img/portfolio/apps/app-fledermaeuse-animated.gif';
import ch from '../assets/img/portfolio/apps/app-character.png';
import ch_animated from '../assets/img/portfolio/apps/app-character-animated.gif';
import gn from '../assets/img/portfolio/apps/app-live-graphic-novel.png';
import gn_animated from '../assets/img/portfolio/apps/app-live-graphic-novel-animated.gif';
import up from '../assets/img/portfolio/apps/app-unplugged.png';
import up_animated from '../assets/img/portfolio/apps/app-unplugged-animated.gif';

export const categories = {
  "fledermaeuse": {
    name: 'Nacktaktive Fledermäuse',
    img: fl,
    img_animated: fl_animated,
  },
  "character": {
    name: 'Character Designs',
    img: ch,
    img_animated: ch_animated,
  },
  "live-graphic-novel": {
    name: 'Live Graphic Novel',
    img: gn,
    img_animated: gn_animated,
  },
  "unplugged": {
    name: 'Unplugged',
    img: up,
    img_animated: up_animated
  },
};
