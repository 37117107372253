function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { return images[item.replace('./', '')] = r(item); });
  return images;
}
const fledermaeuse = importAll(require.context('../assets/img/portfolio/mock/fledermaeuse', false, /\.(png|jpe?g|svg|gif)$/));
const character = importAll(require.context('../assets/img/portfolio/mock/character', false, /\.(png|jpe?g|svg|gif)$/));
const liveGraphicNovel = importAll(require.context('../assets/img/portfolio/mock/live-graphic-novel', false, /\.(png|jpe?g|svg|gif)$/));
const unplugged = importAll(require.context('../assets/img/portfolio/mock/unplugged', false, /\.(png|jpe?g|svg|gif)$/));

export default {
  fledermaeuse,
  character,
  'live-graphic-novel': liveGraphicNovel,
  unplugged
}