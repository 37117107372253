import { SceneInterface, StartSceneConfig, StartSceneProps } from "../../../types";
import {addScene} from "./addScene";

const defaultConfig = {
  localScenes: [],
  disableGlobalScenes: false,
};

interface Return {
  disableGlobal: boolean,
  ready: boolean,
  scenes: SceneInterface[]
}

export const start = (
  scenes: SceneInterface[],
  config: StartSceneProps
): Return => {
  return getNewScenes(scenes, config)
};

const getNewScenes = (
  scenes: SceneInterface[],
  config: StartSceneProps
) => {
  const { localScenes, disableGlobalScenes, origin } = createConfig( config );
  let newScenes = scenes;
  //@ts-ignore
  if ( localScenes && localScenes.length ) {
    newScenes = addScene(scenes, localScenes, origin)
  }

  return {
    disableGlobal: disableGlobalScenes,
    ready: true,
    scenes: newScenes
  };
};

const createConfig = (
  config: StartSceneProps
): StartSceneConfig => ({
  ...defaultConfig,
  ...config
});
