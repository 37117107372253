import React, { useRef } from 'react';
import Button from "../../../components/Button";
import { useNavigation } from "../../../assets/useNavigation";
import { Pages } from "../../../constants/Pages";
import { animation } from "./animation";
import { useAnimateOnReady } from "../../../assets/useAnimateOnReady";
import { useComponentMount } from "../../../assets/useComponentMount";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  bottom: 40px;
  right: -200px;
`;

const ReadMoreButton = () => {
  const navigate = useNavigation();
  const setDomNodes = useAnimateOnReady( animation );
  const onClick = () => navigate( Pages.BOOK );
  const buttonRef = useRef( null );
  useComponentMount(() => setDomNodes({buttonRef}));

  return (
    <Container ref={ buttonRef }>
      <Button
        value='Zum Buch'
        onClick={ onClick }
      />
    </Container>
  );
};

export default ReadMoreButton
