import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Store } from "../../store/reducers/Store";
import { Frame, SceneInterface, SceneList } from "../types";
import { getNextScene } from "../store/actions/methods/helper/getNextScene";
import { getFrameIndex } from "../store/actions/methods/helper/getFrameIndex";

type Target = string | EventTarget

type Return = {
  currentScene: SceneInterface;
  currentFrame: Frame;
  nextFrame: Frame;
  nextScene: SceneInterface;
  next: string;
  getNextFrame(target?: Target): Frame;
}

export const useSceneGetter = (): Return => {
  const sequence = useSelector( (state: Store) => state.sequence );
  const session = useSelector( (state: Store) => state.session );
  const scenes = useSelector( (state: Store) => state.sequence.scenes );
  const current = useSelector( (state: Store) => state.sequence.current );
  const frameIndex = useSelector( (state: Store) => state.sequence.frameIndex );

  const currentScene = useMemo( () =>
    scenes.filter( scene => scene.id === current )[0] || {}
    , [ current, scenes ] );

  // Sometimes the whole event is given. Then it should be handled like undefined
  // This is the complex one

  const next = useMemo( () => {
    const { played, current } = sequence;
    const next = {
      ...sequence,
      played: played.concat( current )
    };
    return getNextScene( next, session );

  }, [ sequence, session ] );

  const nextScene = useMemo( () => {
    return getById( next, scenes )
  }, [ next, scenes ] );

  const currentFrame = useMemo( () => {
      if ( currentScene && currentScene.frames ) {
        return currentScene.frames[frameIndex]
      }
      return {} as Frame
    }
    , [ currentScene, frameIndex ] );

  const nextFrame = useMemo( () => {
      if ( currentScene && currentScene.frames && currentScene.frames[frameIndex + 1] ) {
        return currentScene.frames[frameIndex + 1]
      }
      return {} as Frame
    }
    , [ currentScene, frameIndex ] );

  const getNextFrame = (target?: string): Frame => {
    const index = target && getFrameIndex( currentScene, target );
    if ( index && index !== -1 ) {
      return currentScene.frames[index]
    }
    return nextFrame;
  };

  return {
    currentScene,
    nextScene,
    currentFrame,
    nextFrame,
    next,
    getNextFrame
  }
};

const getById = (id: string, scenes: SceneList) => scenes
  .filter( (el) => el.id === id )[0];
