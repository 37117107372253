import React, { useEffect } from 'react';
import styled from 'styled-components';
import MenuBg from "./MenuBg";
import MenuItems from "./MenuItems";
import { animation as backgroundAnimation } from "./animation/animateLayers";
import { animation as itemAnimation } from "./animation/animateMenuItems";
import { useAnimation } from "../assets/useAnimation";
import { useMenu } from "./store";
import { usePrevious } from "../assets/usePrevious";
import { Pages } from "../constants/Pages";

type Link = { name: string; to: string }

export interface MenuProps {
  links: Link[];
}

export const links = [
  { name: 'Home', to: Pages.HOME },
  { name: 'Portfolio', to: Pages.PORTFOLIO },
  { name: 'Shop', to: 'https://www.etsy.com/de/shop/Bentelicious' },
  { name: 'Das Buch', to: Pages.BOOK  },
  { name: 'Über mich', to: Pages.ABOUT },
  { name: 'Kontakt', to: Pages.CONTACT },
  { name: 'Impressum', to: Pages.IMPRINT },
];

const Container = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
`;

const Menu = () => {
  const background = useAnimation( backgroundAnimation );
  const items = useAnimation( itemAnimation );
  const [
    { isOpen },
    { disable, enable }
  ] = useMenu();
  const wasOpen = usePrevious( isOpen );

  const onStart = disable;
  const onComplete = enable;

  useEffect( () => {
    if ( isOpen && !wasOpen ) {
      background.timeline.in();
      items.timeline.in( { onStart, onComplete } );
    }
    if ( !isOpen && wasOpen ) {
      background.timeline.out();
      items.timeline.out( { onStart, onComplete } );
    }
  }, [isOpen, wasOpen, background, items, onStart, onComplete] );


  return (
    <Container style={ !isOpen ? { pointerEvents: 'none' } : {} }>
      <MenuBg setDomNodes={ background.setDomNodes } />
      <MenuItems setDomNodes={ items.setDomNodes } links={ links } />
    </Container>
  );
};

export default Menu
