import TimelineMax from 'assets/utilz/timeline';
import { MessageDom } from "../store/actions/types";

export const animateMessage = (refs: MessageDom) => ({
  in: () => animateIn( refs ).play(),
});

const animateIn = (
  {
    avatarFace,
    avatarBackground,
    messageBody,
    message,
  }: MessageDom
) => {
  return new TimelineMax( { paused: true } )
    .set(message, {visibility: 'initial'}, 0)
    .set(message, {maxHeight: '100px'}, 0)
    .add(animateMessageIn(avatarFace, avatarBackground, messageBody), '.2')
};

const animateMessageIn = (
  avatarFace: HTMLElement,
  avatarBackground: HTMLElement,
  messageBody: HTMLElement,
) => new TimelineMax()
  .add( avatarBackgroundIn( avatarBackground ), 0 )
  .add( avatarFaceIn( avatarFace ), 0 )
  .add( messageIn( messageBody ), 0 );

const messageIn = (message: HTMLElement) =>
  new TimelineMax()
    .fromTo( message, {
      // transformOrigin: '50% 90%',
      opacity: 0,
      scaleX: .7
    }, {
      duration: .25,
      ease: "power2.out",
      opacity: 1,
      scaleX: 1
    } );

const avatarBackgroundIn = (avatarBackground: HTMLElement) =>
  new TimelineMax()
    .fromTo( avatarBackground, {
      transformOrigin: '50% 90%',
      scale: 0
    }, {
      ease: "power2.out",
      duration: .4,
      scale: 1
    } );

const avatarFaceIn = (avatarFace: HTMLElement) =>
  new TimelineMax()
    .fromTo( avatarFace, {
      transformOrigin: '50% 90%',
      scale: 0
    }, {
      ease: "power3.out",
      duration: .25,
      scale: 1
    } );
