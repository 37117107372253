import { useSelector } from "react-redux";
import { Store } from "../../store/reducers/Store";
import { SessionState } from "./types";

export const useSessionForValidation = (): SessionState => {
  const filtered_scenes = useSelector( (state: Store) => state.session.filtered_scenes );
  const times_visited = useSelector( (state: Store) => state.session.times_visited );
  const visited_pages = useSelector( (state: Store) => state.session.visited_pages );
  const played = useSelector( (state: Store) => state.sequence.played );

  return {
    filtered_scenes,
    times_visited,
    visited_pages,
    played
  }
};
