import { combineReducers } from 'redux';
import { default as sceneReducers } from "../scene/store/reducers";
import { default as chatReducers } from "../scene/Chat/store/reducers";
import { default as testReducers } from '../TestRig/store/reducers';
import { default as sessionReducers } from '../session/store/reducers';
import { default as imageReducer } from '../portfolio/store/reducers';

export default combineReducers( {
  sequence: sceneReducers,
  chat: chatReducers,
  test: testReducers,
  session: sessionReducers,
  images: imageReducer,
} );
