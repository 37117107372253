import { useComponentMount } from "./useComponentMount";
import { useDispatch } from "react-redux";

export const useScreenSizeListener = () => {
  const dispatch = useDispatch();
  const onScreenSizeChange = (width: number, height: number) => {
    dispatch({
      type: 'SET_SCREEN_SIZE',
      width,
      height
    })
  };

  useComponentMount(() => {
    onScreenSizeChange(window.innerWidth, window.innerHeight)
  });

  useComponentMount(() => {
    window.addEventListener("resize", function() {
      onScreenSizeChange(window.innerWidth, window.innerHeight);
    }, false)
  });
};
