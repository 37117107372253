import { useHistory, useLocation } from "react-router";
import { useMenu } from "../menu/store";
import { useSession } from "../session/store";
import { useDispatch } from "react-redux";
import { useCallback } from "react";

export const useNavigation = () => {
  const menu = useMenu()[1];
  const history = useHistory();
  const { pathname } = useLocation();
  const session = useSession();
  const dispatch = useDispatch();

  const handleNavigation = useCallback((to: string) => {
    menu.close();
    history.push( to );
    dispatch( { type: 'SET_PAGE', page: to } );
    dispatch({type: 'RESET_CHAT'})
  }, [dispatch, history, menu]);

  return (to: string) => {
    if ( to.startsWith( 'http' ) ) {
      window.open( to, '_blank' );
    } else {
      if ( pathname !== to ) {
        handleNavigation(to)
      }
    }
    session.addVisitedPage( to )
  };
};
