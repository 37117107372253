import { Actions, CurrentImage, SequenceState } from "./actions/types";
import { Pages } from "../../constants/Pages";
import { CreateScene, StartSceneProps } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { getImage } from "./actions/methods/helper/getImage";
import { addScene as addSceneMethod } from "./actions/methods/addScene";
import { useLocation } from "react-router";
import { useSceneGetter } from "../hooks/useSceneGetter";
import { getNextScene } from "./actions/methods/helper/getNextScene";
import { Store } from "../../store/reducers/Store";
import { actions as getActions } from "./actions/methods/actions";
import { useOnProgress } from "../hooks/useProgress";
import { ChatImage } from "../../constants/chatImages";
import { DialogImage } from "../../constants/dialogImages";

export const useScene = (): Actions => {
  const dispatch = useDispatch();
  const location = useLocation();
  const session = useSelector( (state: Store) => state.session );
  const sequence = useSelector( (state: Store) => state.sequence );
  const showScene = useSelector( (state: Store) => state.sequence.show );
  const scenes = useSelector( (state: Store) => state.sequence.scenes );
  const is_animated_in = useSelector( (state: Store) => state.sequence.is_animated_in );
  const is_animated_out = useSelector( (state: Store) => state.sequence.is_animated_out );
  const isReady = sequence.status === SequenceState.INITIALIZED;
  const { currentScene, currentFrame, getNextFrame } = useSceneGetter();
  const actions = getActions( dispatch );
  const onProgress = useOnProgress();

  const start = (config: StartSceneProps): void => {
    if ( isReady ) {
      console.error( 'set Scenes is not handled yet' )
    } else {
      actions.SET_PAGE_CONFIG( config );
    }
  };

  const getNewScenes = useCallback( (
    scene: CreateScene | CreateScene[],
    origin: Pages | 'GLOBAL' = location.pathname as Pages
  ) => {
    const newScenes = addSceneMethod( scenes, scene, origin );
    return {
      ...sequence,
      scenes: newScenes
    };
  }, [ location.pathname, scenes, sequence ] );

  const addScene = (
    scene: CreateScene | CreateScene[],
    origin?: Pages | 'GLOBAL'
  ) => {
    const next = getNewScenes( scene, origin );
    const newCurrent = getNextScene( next, session );

    actions.ADD_SCENE( next.scenes, newCurrent );
  };

  const getCurrentScene = () => currentScene;

  const getCurrentFrame = () => currentFrame;

  const getCurrentImage = (image?: ChatImage | DialogImage): CurrentImage => {
    const img = image || currentFrame.image;
    return getImage( img )
  };

  const showAnswer = (): boolean => {
    return currentFrame.type === 'ANSWER' && showScene
  };

  const setAnimatedIn = (state: boolean): void => {
    if ( is_animated_in !== state ) {
      actions.SET_ANIMATED_IN( state );
    }
  };

  const setAnimatedOut = (state: boolean): void => {
    if ( is_animated_out !== state ) {
      actions.SET_ANIMATED_OUT( state );
    }
  };

  const setPage = (page: Pages): void => {
    // actions.SET_PAGE(page);
    console.log( 'needs to set page can also update in session' )
  };

  return {
    initialize: actions.INITIALIZE,
    show: actions.SHOW,
    hide: actions.HIDE,
    getCurrentScene,
    getCurrentFrame,
    getCurrentImage,
    onProgress,
    showAnswer,
    setAnimatedIn,
    setAnimatedOut,
    addScene,
    getNextFrame,
    start,
    setPage,
  };
};
