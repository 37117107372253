import images from './mock_load_images';

interface Images {
  [key: string]: {
    [key: string]: string;
  } | {}
}

export const getImages = (category: string) => {
  const img: Images = images;
  const result = img[category];
  return Object.values(result)
};
