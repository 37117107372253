import React from 'react';
import { useToggle } from "../useToggle";
import { useSession } from "../../../session/store";
import { Button, ButtonList, Toggle } from "../Styles";


const Session = () => {
  const [ show, toggle ] = useToggle(true);
  const session = useSession();

  return (
    <ButtonList>
      <Button onClick={ toggle } active={ show }>
        Session
      </Button>
      <Toggle visible={ show }>
        <Button onClick={session.agreeToDsgvo}>
          Agree to dsgvo
        </Button>
        <Button onClick={session.clearSession}>
          Clear Storage
        </Button>
      </Toggle>
    </ButtonList>
  );
};

export default Session
