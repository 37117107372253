import { SceneInterface, SceneList } from "../../../../../types";

export const getNextInOrder = (scenes: SceneList) =>
  scenes.sort( compare )[0].id;

const compare = ( a: SceneInterface, b: SceneInterface ) => {
  const aa = a.order || 0;
  const bb = b.order || 0;
  if ( aa < bb ){
    return -1;
  }
  if ( aa > bb ){
    return 1;
  }
  return 0;
};
