import React, { useState } from 'react';
import { CategoryType } from "../api/useApi";
import { Container, Image, Name } from "./AppButtonStyles";

interface Props extends CategoryType{
  onClick(): void;
}

const AppButton: React.FC<Props> = (
  {
    name,
    img,
    img_animated,
    onClick
  }
) => {
  const [src, setImage] = useState(img);

  const onMouseOver = () => {
    setImage(img_animated)
  };

  const onMouseOut = () => {
    setImage(img)
  };


  return (
    <Container onClick={onClick}>
      <Image
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        src={src}
      />
      <Name>{name}</Name>
    </Container>
  );
};

export default AppButton
