import React, { useMemo } from 'react';
import { Name, ButtonContainer } from "../styles/MenuItemsStyle";
import { Shape } from "../../shapes";
import { itemShape } from "./itemShape";
import { useNavigation } from "../../assets/useNavigation";
import { Pages } from "../../constants/Pages";
import { links } from "../Menu";

interface Props {
  name: string;
  to: Pages | string;
  index: number;
}

const Item: React.FC<Props> = ({ name, to, index }) => {
  const navigate = useNavigation();
  const form = useMemo( itemShape, [] );

  const displace = useMemo(() =>
    ((links.length - 1) - index) * 10
  , [index]);

  const linkTo = () => {
    navigate( to )
  };

  return (
    <ButtonContainer onClick={ linkTo } displace={displace}>
      <Shape
        form={ form }
        onMouseOver={ [ 'wiggle', 'show' ] }
        textColor='black'
        wiggleOptions={ { x: 40, y: 10 } }
        useClickableArea
      >
      </Shape>
        <Name>{ name }</Name>
    </ButtonContainer>
  );
};

export default Item
