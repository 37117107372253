import React, { useMemo } from 'react';
import AppButton from "./AppButton";
import { useApi } from './useApi';

interface Props {
  onClickApp(name: string): void;
}

const Categories: React.FC<Props> = (
  {
    onClickApp
  }
  ) => {
  const {getCategories, categories} = useApi();
  getCategories();

  const Apps = useMemo( () =>
      categories.map( (category, i) => {
        return (
          <AppButton
            key={ i }
            name={ category.name }
            img={ category.icon }
            img_animated={ category.iconOmo }
            onClick={ () => onClickApp( category.name ) }
          />
        )
      } )
    , [ categories, onClickApp ] );

  return (
    <>
      {Apps}
    </>
  );
}

export default Categories
