import styled from "styled-components";

export const displaceCharacter = '100%';
export const displaceSpeechBubble = '150%';
// Answer
export const displaceUser = '150%';
export const displaceBackdrop = '100%';
export const displaceBoxX = '23%';
export const displaceBoxY = '-250%';

export const DialogContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 300px;
    bottom: 0;
    left: 0;
    display: flex;
    z-index: 500;
`;

export const Character = styled.div`
    align-self: flex-end;
    max-height: 100%;
    transform-origin: 0 100%;
    flex: 0 1 300px;
    transform: translate(0px, ${ displaceCharacter });
`;

export const Image = styled.img`
    width: 100%;
    //width: 130%;
    position: relative;
    left: 0;
    //left: -15%;
`;

export const SpeechBubbleContainer = styled.div`
    width: 700px;
    height: 240px;
    align-self: flex-end;
    position: relative;
    bottom: -20px;
    left: -50px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Titillium Web", sans-serif;
    font-size: 1.5rem;
    transform: translate(0px, ${ displaceSpeechBubble });
`;

export const AnswerContainer = styled.div`
  pointer-events: none;
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 900;
  * {
    pointer-events: none;
  }
`;

export const AnswerBackDrop = styled.div`
  pointer-events: initial;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(${ displaceBackdrop }, 0);
  width: 100%;
  height: 100%;
  z-index: 900;
  background: rgba(0, 0, 0, 0.6);
`;

export const AnswerUser = styled.div`
  position: relative;
  top: 0;
  right: 0;
  //transform: translate(0, ${ displaceUser });
  height: 100vh;
  width: 45vw;
  z-index: 1000;
`;

export const AnswerUserImage = styled.img`
  position: absolute;
  width: 130%;
  bottom: 0;
  right: -40%;
  max-height: 540px;
  object-fit: contain;
  transform-origin: 50% 100%;
  transform: translate(0, ${ displaceUser });
  z-index: 1001;
`;

export const AnswerOptions = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  position: relative;
  right: -120px;
  margin: 0;
  height: 100%;
  font-size: 1.3rem;
  z-index: 1002;
`;

export const DialogChoice = styled.div<{ hide: boolean}>`
  pointer-events: initial;
  position: relative;
  align-self: flex-end;
  color: white;
  height: 50px;
  margin-bottom: 20px;
  display: ${ props => props.hide ? 'none' : 'block' };
  z-index: 500;
  width: 130%;
  right: -80px;
  cursor: pointer;
`;

export const DialogChoiceContainer = styled.div<{ selected?: boolean }>`
  height: 100%;
  transform: scale(${ props => props.selected ? 1.2 : 1 });
  transition: all .15s ease;
`;

export const DialogChoiceText = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 5px 100px 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const AnswerFlash = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1001;
  overflow: visible;
  height: 100vh;
  width: auto;
  transform: translate(${ displaceBoxX }, ${ displaceBoxY });
`;

export const AnswerFlashBackground = styled.div`
  transform: translate(${ displaceBoxX }, ${ displaceBoxY });
`;
