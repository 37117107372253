import styled from "styled-components";

export const Container = styled.div<{ visible: boolean }>`
  pointer-events: ${ props => props.visible ? 'initial' : 'none' };
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 10px 0 30px;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;
export const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  align-self: flex-start;
  flex: 0 0 80px;
  margin: 8px;
  overflow: hidden;
  transition: transform 0.5s ease-out;
`;
export const ImageBackground = styled.div`
  width: 80px;
  height: 80px;
  background-color: white;
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 80px;
  filter: grayscale(1);
  transition: all 0.5s ease-out;
  &:hover {
    transform: translateX(-50%) scale(1.05);
    filter: grayscale(0);
  }
`;