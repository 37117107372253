import { globalScenes } from "../../../constructor/globalScenes";
import { SceneList, StartSceneProps } from "../../../types";
import { Pages } from "../../../../constants/Pages";

type Action = {
  type: string;
  [index: string]: any;
};

type Dispatch = (action: Action) => void;

export const actions = (dispatch: Dispatch) => {

  const INITIALIZE = () => dispatch( {
    type: 'INITIALIZE',
    scenes: globalScenes(),
  } );

  const SET_PAGE_CONFIG = (config: StartSceneProps) => dispatch( {
    type: 'SET_PAGE_CONFIG',
    config
  } );

  const ADD_SCENE = (
    scenes: SceneList,
    current: string
  ) => dispatch( {
    type: 'ADD_SCENE',
    scenes,
    current
  } );

  const SHOW = () => dispatch( {
    type: 'SHOW',
  } );

  const HIDE = () => dispatch( {
    type: 'HIDE',
  } );

  const FINISH_SCENE = () => dispatch( {
    type: 'FINISH_SCENE',
  } );

  const SET_FRAME_INDEX = (index: number) => dispatch( {
    type: 'SET_FRAME_INDEX',
    index
  } );

  const INCREMENT_FRAME = () => dispatch( {
    type: 'INCREMENT_FRAME',
  } );

  const SET_ANIMATED_IN = (is_animated_in: boolean) => dispatch( {
    type: 'SET_ANIMATED_IN',
    is_animated_in
  } );

  const SET_ANIMATED_OUT = (is_animated_out: boolean) => dispatch( {
    type: 'SET_ANIMATED_OUT',
    is_animated_out
  } );

  const SET_PAGE = (page: Pages | 'GLOBAL') => dispatch( {
    type: 'SET_PAGE',
    page
  } );

  return {
    INITIALIZE,
    SET_PAGE_CONFIG,
    ADD_SCENE,
    SHOW,
    HIDE,
    FINISH_SCENE,
    SET_FRAME_INDEX,
    INCREMENT_FRAME,
    SET_ANIMATED_IN,
    SET_ANIMATED_OUT,
    SET_PAGE
  }
};
