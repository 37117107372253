import styled, { keyframes } from "styled-components";
import burgerImage from "../../assets/img/ui/burger.png";

const close_menu = keyframes`
  from { background-position: 0 0; }
  to { background-position: 100% 0; }
`;

const open_menu = keyframes`
  from { background-position: 100% 0; }
  to { background-position: 0 0; }
`;

export const Button = styled.button<{open: boolean}>`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 52px;
  height: 50px;
  background-image: url(${burgerImage});
  background-repeat: no-repeat;
  background-size: 900px 50px;
  background-position: bottom right;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  &:active, :focus {
    outline: none;
  }
  animation: ${props => props.open ? open_menu : close_menu} 0.8s forwards steps(17);
  z-index: 1001;
`;
