import TimelineMax from 'assets/utilz/timeline';


export const animation = (button: any) => ({
  play: () => animateIn( button.buttonRef ).play().delay(5.5),
});

const animateIn = (nodes: any) =>
  new TimelineMax( { paused: true } )
    .to( nodes, {
      duration: 1,
      x: -250,
      ease: 'power4.out'
    } );
