import { useEffect } from "react";
import { Message, Status } from "./store/actions/types";
import { useScene } from "../index";
import { useChat } from "./store/useChat";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store/reducers/Store";
import { SequenceState } from "../store/actions/types";

export const useChatEvents = () => {
  const dispatch = useDispatch();

  const { getCurrentFrame } = useScene();
  const { addMessage } = useChat();
  const answer = useSelector( (state: Store) => state.chat.answer );
  const hasMessages = useSelector( (state: Store) => !!state.chat.messages.length );
  const isReady = useSelector( (state: Store) => state.chat.status === Status.READY );
  const start = useSelector( (state: Store) => state.sequence.status === SequenceState.START );
  const finish = useSelector( (state: Store) => state.sequence.finish );
  const is_animated_out = useSelector( (state: Store) => state.sequence.is_animated_out );
  const isAnswer = useSelector((state: Store) => {
    const {messages} = state.chat;
    return messages.length && messages[messages.length - 1].type === 'ANSWER'
  });

  useEffect( () => {
    // Add Initial Message
    const current = getCurrentFrame();
    const isInitial = current.text && !hasMessages && start;

    if ( isInitial ) {
      addMessage( current as Message )
    }
  }, [addMessage, getCurrentFrame, hasMessages, start] );

  useEffect(() => {
    if (isReady && answer === '' && isAnswer ) {
      dispatch({type: 'AWAIT_ANSWER'})
    }
  }, [answer, dispatch, isAnswer, isReady]);

  useEffect(() => {
    if (finish && is_animated_out) {
      dispatch({type: 'RESET_CHAT'})
    }
  })

};
