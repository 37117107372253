import React from 'react';
import AppRouter from "./AppRouter";
import { useSession } from "./session/store";
import Loading from "./components/Loading/Loading";
import { useSessionStart } from "./session/useSessionStart";
import { useSelector } from "react-redux";
import { Store } from "./store/reducers/Store";
import { useCookieConsent } from "./scene/Event/AgreeDsgvo/useCookieConsent";

function AppLoader() {
  useSessionStart();
  const dsgvo_agreed = useSelector( (state: Store) => state.session.is_read && state.session.dsgvo_agreed );
  useCookieConsent(dsgvo_agreed)

  const { isReady } = useSession();
  const is_ready = isReady();

  return is_ready
    ? <AppRouter />
    : <Loading show />
}

export default AppLoader;
