import { CreateScene, SceneInterface } from "../../../types";
import { createScene } from "../../../constructor/createScene";
import { Pages } from "../../../../constants/Pages";
import { addUnique } from "./helper/addUnique";

export const addScene = (
  scenes: SceneInterface[],
  scene: CreateScene | CreateScene[],
  origin: Pages | 'GLOBAL',
): SceneInterface[] => {
  const newScenes = createArray( scene, origin );
  return addUnique( scenes, newScenes );
};

const createArray = (
  added: CreateScene | CreateScene[],
  origin: Pages | 'GLOBAL'
): SceneInterface[] => {
  // @ts-ignore
  const scenes: CreateScene[] = [].concat( added );

  return scenes.map( sceneProps => {
    const scene = createScene( sceneProps );

    return {
      ...scene,
      origin: origin || scene.origin
    }
  });
};
