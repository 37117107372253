import React, { Fragment, useEffect, useState } from 'react';
import Frame from "./Frame";
import Arrow from "./Arrow";
import { usePrevious } from "../../assets/usePrevious";
import { Container, FullScreen, Image } from "./SliderStyle";
import Close from "./Close";
import Indexing from "./Indexing";

interface Props {
  images: string[];
  initialIndex?: number;
  fullScreen?: boolean;
  show?: boolean;
  onClose?(): void;
}

const Slider: React.FC<Props> = (
  {
    images,
    initialIndex = 0,
    fullScreen,
    show,
    onClose,
  }
  ) => {
  const [ index, setIndex ] = useState( initialIndex );
  const prevIndex = usePrevious(initialIndex) || initialIndex;

  useEffect(() => {
    if (initialIndex >=0 ) {
      setIndex( initialIndex );
    }
  }, [initialIndex, prevIndex]);

  const getPrev = () => index > 0 ? index - 1 : images.length - 1;

  const getNext = () => index + 1 < images.length ? index + 1 : 0;

  const onPrev = () => {
    setIndex( getPrev() )
  };

  const onNext = () => {
    setIndex( getNext() )
  };

  const Wrapper = fullScreen ? FullScreen : Fragment;

  if (show === false) return null;

  return (
    <Wrapper>
      <Container>
        <Frame index={index}>
          <Image src={ images[index] } alt="" />
          <Indexing index={index} length={images.length} />
        </Frame>
        <Arrow onClick={onPrev} left />
        <Arrow onClick={onNext} />
        { !!onClose &&
          <Close onClick={ onClose } />
        }
      </Container>
    </Wrapper>
  );
};

export default Slider
