import { createStore } from 'redux';
// import thunk from 'redux-thunk';
import { compose } from 'redux';
import rootReducer from './rootReducer';

//@ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
  // applyMiddleware(thunk),
);

export default createStore(
  rootReducer,
  enhancer
);
