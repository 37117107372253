import { Pages } from "../../constants/Pages";
import { SequenceState, State } from "./actions/types";


export type Action = {
  type: string;
  [index: string]: any;
}

export const initialState: State = {
  scenes: [],
  played: [],
  current: '',
  show: false,
  frameIndex: 0,
  is_animated_in: false,
  is_animated_out: true,
  delay: 0,
  disableGlobal: false,
  ready: false,
  end: false,
  page: Pages.HOME,
  status: SequenceState.LOADING,
  pageConfig: null,
  finish: false,
  event: '',
};

export default function reducer(state: State = initialState, action: Action) {
  switch ( action.type ) {

    case 'INITIALIZE':
      return {
        ...state,
        scenes: action.scenes,
        status: SequenceState.INITIALIZED
      };

    case 'SET_PAGE':
      return {
        ...state,
        page: action.page,
        status: SequenceState.AWAIT_CONFIG
      };

    case 'SET_PAGE_CONFIG':
      return {
        ...state,
        pageConfig: action.config,
        status: SequenceState.AWAIT_SCENES
      };

    case 'SET_UP_SEQUENCE':
      return {
        ...state,
        disableGlobal: action.disableGlobal,
        scenes: action.scenes,
        status: SequenceState.AWAIT_START,
        pageConfig: null
      };

    case 'AWAIT_READY':
      return {
        ...state,
        status: SequenceState.AWAIT_READY,
      };

    case 'START':
      return {
        ...state,
        status: SequenceState.START,
      };

    case 'ADD_SCENE':
      return {
        ...state,
        scenes: action.scenes,
      };

    case 'SET_CURRENT':
      return {
        ...state,
        current: action.current,
        show: action.show || !!action.current.length,
        frameIndex: 0
      };

    case 'SHOW':
      return {
        ...state,
        show: true,
      };

    case 'HIDE':
      return {
        ...state,
        show: false,
      };

    case 'INCREMENT_FRAME':
      return {
        ...state,
        frameIndex: state.frameIndex + 1,
      };

    case 'SET_FRAME_INDEX':
      return {
        ...state,
        frameIndex: action.index,
      };

    case 'SET_EVENT':
      return {
        ...state,
        event: action.event,
      };

    case 'RESET_EVENT':
      return {
        ...state,
        event: initialState.event,
      };

    case 'SET_ANIMATED_IN':
      return {
        ...state,
        is_animated_in: action.is_animated_in,
      };

    case 'SET_ANIMATED_OUT':
      return {
        ...state,
        is_animated_out: action.is_animated_out,
      };

    case 'NEXT_SCENE':
      return {
        ...state,
        current: action.current,
        delay: action.delay,
        frameIndex: 0,
        played: action.played,
        finish: false,
      };

    case 'FINISH_SCENE':
      return {
        ...state,
        finish: true,
        show: false,
      };

    default: return state
  }
};
