import React from "react";
import useGlobalHook from "use-global-hook";
import { State, Actions } from "./actions/types";
import * as actions from './actions';

const initialState: State = {
  isOpen: false,
  ready: false,
  isDisabled: false
};

export const useMenu = useGlobalHook<State, Actions>( React, initialState, actions );
