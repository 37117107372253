import React, { useCallback } from 'react';
import { Props } from "../Scene";
import MobileDom from "./MobileDom";
import { Hideable } from "../../components/Hideable";
import { useChat } from "./store/useChat";
import { Refs } from "../../assets/useDomNodes";
import { useVisibilityAnimation } from "../hooks/useVisibilityAnimation";
import { animateMobile } from "./animation/animateMobile";
import { SceneType } from "../types";
import Messages from "./Messages";
import ChatPath from "./ChatPath";
import Answer from "./Answer";
import { useChatEvents } from "./useChatEvents";
import ChatDots from "./ChatDots";

const Chat: React.FC<Props> = (
  {
    hide,
    dom,
    setDomNodes
  }
) => {
  useVisibilityAnimation( dom, animateMobile, SceneType.CHAT );
  useChatEvents();
  const { onProgress } = useChat();

  const setReady = useCallback(
    (refs: Refs) => {
      setDomNodes( refs );
    },
    [ setDomNodes ]
  );

  return (
    <Hideable hide={ hide }>
      <MobileDom
        onProgress={ onProgress }
        setReady={ setReady }
      >
        <Messages />
        <ChatPath />
        <Answer />
        <ChatDots />
      </MobileDom>
    </Hideable>
  )
};

export default Chat
